* {
    font-size: 12px;
}

:root {
    --primary-color: #f7941d;
    --primary-color-2: #f8a540;
    --text-color-1: #FFF;
    --primary-color-3: #5d5d5d;
    --primary-color-4: #707070;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.font-size-1 {
    font-size: 14px !important;
}

input.mandatory-field, select.mandatory-field, label.mandatory-field, textarea.mandatory-field, span.rc-time-picker.mandatory-field > input.rc-time-picker-input {
    border-left-color: var(--primary-color);
    border-left-width: 3px;
}

label.mandatory-field.custom-file-label::after {
    border-left: none;
}

.float-right {
    position: relative;
    z-index: 999;
}

form span.error, form span.error-span {
    color: #FA0000;
}

label {
    font-weight: 600;
}

#frmLogin .spinner-border {
    color: var(--text-color-1);
}

.spinner-border {
    color: var(--primary-color);
}

#root > div > div.gig-sidebar {
    display: block;
}

/* #root > div > div.gig-body{
  margin-left: 16.666667% !important;
} */

#root > div.active > div.gig-sidebar {
    display: none;
}

#root > div.active > div.gig-body {
    margin-left: 0 !important;
    max-width: 100% !important;
    flex: 0 0 100%;
}

i.icofont-size-2 {
    font-size: 18px !important;
}

i.fas-size-1 {
    font-size: 18px !important;
}

i.fas-size-2 {
    font-size: 28px !important;
}

i.fas-size-3 {
    font-size: 18px !important;
}

a.gig-link {
    color: var(--primary-color) !important;
    text-decoration: none;
}

a.gig-link:hover {
    text-decoration: none;
}

a.gig-link-2 {
    color: inherit !important;
    text-decoration: none;
}

a.gig-link-2:hover {
    text-decoration: none;
}

.btn-primary, .btn-secondary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.gig-login {
    min-height: 100vh;
}

.gig-login > div {
    margin: auto;
}

.gig-login img.logo {
    height: 37px;
}

.gig-login h1 {
    font-weight: 400;
    font-size: 22px;
}

.gig-sidebar {
    color: #fff;
    background: #3c4b64;
    position: fixed !important;
    top: 0;
    bottom: 0;
    z-index: 1030;
    overflow-y: auto;
    overflow-x: hidden;
}

/* .gig-body{
    margin-left: 16.666667% !important;
} */

.gig-sidebar a.logo {
    display: block;
    padding: 20px 10px;
    border-bottom: 1px solid var(--primary-color-4);
}

.gig-sidebar a.logo > img {
    width: 100%;
}

.navigation {
    margin-top: 20px;
    width: 100%;
}

/* reset our lists to remove bullet points and padding */
.mainmenu, .submenu {
    list-style: none;
    padding: 0;
    margin: 0;
}


/* make ALL links (main and submenu) have padding and background color */
.mainmenu a {
    display: block;
    text-decoration: none;
    padding: 10px;
    color: var(--text-color-1);
    border-bottom: 1px solid var(--primary-color-4);
    font-size: 14px;
}

.submenu a {
    border-bottom-color: #b99393 !important;
}


.mainmenu li:last-child > a {
    border-bottom: none;
}

/* add hover behaviour */
.mainmenu a:hover, .mainmenu a.active {
    background-color: var(--primary-color-3);
    color: var(--primary-color);
    text-decoration: none;
}

.mainmenu a.hassubmenu::after {
    font-family: 'IcoFont' !important;
    content: '\ea99';
    float: right;
}

.mainmenu a.open::after {
    content: '\eaa1';
}


/* when hovering over a .mainmenu item,
  display the submenu inside it.
  we're changing the submenu's max-height from 0 to 200px;
*/

.mainmenu li.open .submenu {
    display: block !important;
    max-height: 200px;
    overflow-y: auto;
}

/*
  we now overwrite the background-color for .submenu links only.
  CSS reads down the page, so code at the bottom will overwrite the code at the top.
*/

.submenu a {
    background-color: var(--primary-color-4);
}

/* hover behaviour for links inside .submenu */
.submenu a:hover {
    background-color: #666;
}

/* this is the initial state of all submenus.
  we set it to max-height: 0, and hide the overflowed content.
*/
.submenu {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 0.5s ease-out;
}

.gig-content {
    min-height: calc(100vh - 116px);
}

.gig-footer {
    height: 40px;
    overflow: hidden;
    font-size: 12px;
    color: #FFF;
    background-color: var(--primary-color-3);
    padding-top: 10px;
    padding-bottom: 10px;
}

.gig-header {
    height: 76px;
    border-bottom: 1px solid var(--primary-color-4);
    padding: 17px 10px;
    background-color: #F2F2F2;
}

.gig-footer img {
    height: 18px;
}

.gig-header .gig-toggle {
    padding: 13px;
}

.gig-header .dropdown {
    padding: 10px 0;
}

.gig-header .dropdown a {
    font-size: 14px;
}

.dropdown-menu {
    background-color: var(--primary-color-3) !important;
}

.dropdown-menu .dropdown-item {
    color: var(--text-color-1) !important;
    border-bottom: 1px solid var(--primary-color-4);
}

.dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd !important;
}

.gig-header .dropdown-menu {
    right: 0;
}

.gig-header .dropdown-menu .dropdown-item {

    font-size: 14px;
    padding: 5px;

}

.dropdown-menu .dropdown-item:last-child {
    border: none;
}

.dropdown-menu .dropdown-item:hover {
    background-color: var(--primary-color) !important;
    color: white !important;
}

.gig-content h1 {
    font-weight: bold;
    font-size: 19px;
    padding: 10px 0px;
}

.gig-content h2 {
    font-weight: 400;
    font-size: 18px;
    padding: 10px 0;
}

.react-bootstrap-table-pagination-list > ul {
    float: right;
}

.page-item .page-link {
    color: var(--primary-color-3) !important;
}

.page-item.active .page-link {
    background-color: var(--primary-color) !important;
    color: var(--text-color-1) !important;
    border-color: var(--primary-color) !important;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}

.react-bootstrap-table thead > tr > th {
    background-color: #F2F2F2;
}

.react-bootstrap-table tbody > tr:hover {
    background-color: #F2F2F2;
}

.gig-content-loader, .gig-popup-loader {
    display: none;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: 5%;
    background-color: rgba(100, 100, 100, 0.3);
    z-index: 999;
}

.gig-content-loader > div {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.gig-popup-loader > div {
    margin-left: auto;
    margin-right: auto;
}

.modal-header button.close > span {
    font-size: 18px;
}

table.carrier-view {
    table-layout: fixed;
}

table.carrier-view th, table.carrier-view td {
    word-wrap: break-word;
    width: 12.5%;
}

table.carrier-view th {
    background-color: #f7f5f5;
}

.modal-body.frmNewFilters {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
}

.rct-node-icon {
    display: none !important;
}

.rct-icons-fa4 .rct-icon-uncheck::before, .rct-icons-fa4 .rct-icon-check::before, .rct-icons-fa4 .rct-icon-half-check::before {
    font-size: 15px;
}

.rct-title {
    font-weight: normal !important;
}

#frmNotiMembers .user_types > .list-group-item, #frmNotiMembers .users > .list-group-item, #frmNotiMembers .notificationTypes > .list-group-item {
    cursor: pointer;
}

a.table-action {
    font-size: 14px;
}

.modal-body > p {
    max-height: 200px;
    word-wrap: break-word;
    overflow: auto;
}

span.form-control-timer.rc-time-picker {
    width: 100%;
}

.react-datepicker-wrapper {
    display: block !important;
}

#merge td:nth-child(2), #merge th:nth-child(2) {
    background-color: #d4d4d4;
}

td > i.status1 {
    color: rgb(139, 139, 139);
}

td > i.status2 {
    color: rgb(26, 100, 57);
}

/*************Top Menu ************/
.drop-down02 {
    position: relative;
}

.drop-down02 .sub-menu02 {
    /* position: absolute !important; */
    left: 100%;
    top: 0;
}

/* .drop-down02 .dropdown-toggle {
  padding: 0.5rem 1.1rem !important;
} */

@media screen and (min-width: 767px) {
    .drop-down02 .sub-menu02 {
        position: static !important;
    }
}

@media screen and (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-submenus {
        position: absolute !important;
        z-index: 99;
    }
}

@media screen and (max-width: 804px) {
    .footlogo {
        font-size: 11px;
    }

    .version p {
        font-size: 11px;
    }

    .footimg img {
        width: 110px;
        float: right;
    }
}

footer {
    width: 100%;
    background-color: #5d5d5d;
}

.head1 {
    background-color: #3c4b64;
}

.newlogo {
    width: 40px;
}

.collapse {
    background-color: #3c4b64;
}

.collapse a {
    display: inline-block;
    color: white;

    text-decoration: none;
}

.collapse a:hover {
    background-color: #777;
    border-color: #b99393;
    color: rgb(247, 148, 29);
    text-decoration-color: rgb(247, 148, 29);
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.dropdown-submenus {
    background-color: #3c4b64;
}

.drop-down02 a:hover {
    color: rgb(247, 148, 29) !important;
}

.drop2 a:hover {
    color: rgb(247, 148, 29) !important;
}

.drop3 a:hover {
    color: rgb(247, 148, 29) !important;
}

@media screen and (max-width: 992px) {
    .login-menu .dropdown-submenus.show {
    }
}

.login-menu .dropdown-submenus.show {
    right: 0px;
    left: unset;
}

.no-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.main {
    height: calc(100vh - 110px);
}

.login-menu .nav-item {
    display: inline-block;
}

.version {
    color: white;
    text-align: center;
}

.footlogo {
    color: white;
    padding-top: 14px;
}

#noti-count {
    width: 20px;
}

.badge-warning {
    color: black;
    background-color: rgb(247, 148, 29);
}

.float-right {
    float: right;
}

.popover-body > ul > li:first-child > span {
    font-size: 14px;
    font-weight: 1600;
}

.notify {
    width: 350px;
}

#notify {
    font-size: 20px;
    font-weight: bold;
}

ul > li:first-child {
    border: none;
}

ul > li {
    border-top: 1px solid white;
    padding: 5px;
}

ul > li > h5 {
    font-size: 14px;
    display: inline-block !important;
    color: white;
}

ul > li > p {
    color: white;
    font-size: 12px;
    margin-bottom: 0px;
}

ul > li > span {
    font-size: 9px;
    width: 20px;
    /* color: white; */
}

.text-center {
    text-align: center;
}

.drop2 {
    border: none;
}

.set-drop {
    border: none;
}

.notify li:hover {
    color: rgb(247, 148, 29) !important;
    background-color: #3c4b64 !important;
}

/*  .nav-link {
    color: #fff;
}*/

.gig-image {
    width: 200px;
    margin-left: 131px;
}

.paddingleft {
    padding-left: 30px !important;
}

.modal.show .modal-dialog.modal-90w {
    max-width: 925px;
}


.get-button {
    border: 3px solid #f7941d;
    width: 150px;
    background-color: #f7941d;
    height: 40px;

}

.get-button:hover {
    background-color: white;
}

.start-text {
    text-decoration: none;
    padding: 12px;
    font-size: 20px;
    text-align: center;
    color: white;
}

.welcome-text {
    font-size: 30px !important;
    font-weight: bolder !important;
    /* color: white; */
}

.welcome-text span {
    font-size: 30px !important;
    font-weight: bolder !important;
    color: #f7941d;
}
.back {
    background-color: white;
    opacity: 0.7;
    color: black;
    height: 400px;
    padding: 25px;


}

.inner-row {
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.back-row {
    padding-left: 0px !important;
    margin-left: 0px !important;
}


.to-word {
    color: #f7941d;
    font-weight: bolder;
}

.card-header {
    background-color: red !important;
}

#border {
    background-color: green !important;
}


.card-body > ul {
    height: 80vh;
    overflow: auto;
}

.trucklogo {
    width: 100%;
    height: 100vh;
}

.companylogo {
    width: 50%;
    height: 100%;

}

.description {
    font-size: 15px;
}

.main-row {
    width: 100vw;
}

.iconcolor {
    color: #f7941d;
}

.load-tab {
    margin-left: 30px;
    padding: 20px;
}

.border {
    border: solid 1px #dee2e6;
    padding: 5px;
}

.space {
    border: solid 1px #dee2e6;
    padding: 5px;
}

.menu {
    margin-left: 30px;
    padding-left: 20px;
}
.drop-image {
    width: 100%;
    height: 100%;
}

.img-divider {
    padding-right: 0px !important;
    border-right: 5px solid rgb(247, 148, 29);
}

.drop-vendor {
    color: rgb(247, 148, 29);
}

.groupseparator{
    display:flex;
    align-items: center;
  }
  
  .groupseparator .leftline{
    height: 2px;
    flex: 1;
    background-color: #dee2e6;
   
  }
  .groupseparator .rightline{
    height: 2px;
    background-color: #dee2e6;
    width:5%;
  }
  
  .groupseparator h2{
    padding: 0 8px;
    color: #f7941d;
    font-weight: bold;
    font-size:14px;
  }

 .hello-can{

    color:#f7941d;
    font-size: 25px;
    font-weight: 300;
    display: block;
}

 .jKLQUD{
    display: block !important;
}
/* -----------Loadboard css------------------ */
.copy-btn {
    background-color: rgb(105, 170, 79);
    border: rgb(105, 170, 79);
    color: #ffff;
    width: max-content;
    margin-top: 25px;
    border-radius: 3px;
}

.edit-btn {
    background-color: rgb(8, 196, 243);
    border: rgb(8, 196, 243);
    color: #ffff;
    width: max-content;
    margin-top: 25px;
    border-radius: 3px;
}

.del-btn {
    background-color: rgb(241, 105, 50);
    border: rgb(241, 105, 50);
    color: #ffff;
    width: max-content;
    margin-top: 25px;
    border-radius: 3px;
}

.search-btn {
    background-color: #f7941d;
    border: #f7941d;
    color: #ffff;
    width: max-content;
    margin-top: 25px;
    border-radius: 3px;
}

td.expand-cell{
    cursor: pointer !important;
}

.phone-number-select-width{
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

iframe {
    display: none !important;
    visibility: hidden !important;
  }

  .react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 330px;
    height: 30px;
    padding: 10px 10px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 11px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 30px;
    width: 320px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
@media (min-width: 1024px) {
    .saveAndCloseBtn {
        margin-left: 400px !important;
        margin-top: -30px !important;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }
}
.expand-cell-header{
    cursor: pointer;
}

nav.nav.nav-tabs {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nav-tabs .nav-link.active{
    font-weight: bold !important;
    color: #f7941d !important;
    text-transform: uppercase !important;
    border-color:none !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.nav-tabs .nav-link{
    font-weight: 500 !important;
    color: black !important;
    text-transform: uppercase !important;
    border-color:none !important;
}

/* a{
    color: black !important;
    text-transform: uppercase;
} */